import React from 'react'

import Layout from '../../../components/layout'
import SEO from "../../../components/seo"
// import { Helmet } from 'react-helmet'
import TwoColumn from "../../../components/two-column"
import CustomerJourney from '../../../components/customer-journey'

const increaseMMR = {
  title: "Increase your MRR with flexible add-ons and allowances.",
  description: [
    {
      text: "Adding more options for your customers is a sure-fire way to increase MRR - and usage based billing helps you to charge customers for what they use, too. All of these advanced options are included for every Billsby customer."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free'
    },
    {
      buttonColor: 'black',
      scheduleButton: true
    }
  ],
  sectionDevelopers: true,
  image: require('../../../images/addons-allowances.svg'),
  srcSet: 'addons-allowances.svg 2x',
  svg: true,
  imageName: 'addons-allowances svg',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right'
}

const bundleAddons = {
  title: "Bundle add-ons to create the perfect plan for each customer.",
  list: [
    {
      title: 'Add-ons for additional features',
      description: <>Let customers take add-ons to enjoy additional services - like access to the spa at a gym, or a white label version of your SaaS product.</>,
    },
    {
      title: 'Force add-ons with selected plans',
      description: <>Create bundled plans with add-ons included - our system will automatically calculate the bundled price and ensure compliance in the checkout flow.</>,
    },
    {
      title: 'Use all of our pricing models with add-ons',
      description: <>Flat-fee, per unit, volume, ranged and tiered pricing is all available with add-ons, to give you the flexibility you need to build your perfect plan.</>,
    }
  ],
  image: require('../../../images/addon-allowance-1.svg'),
  imageName: 'billsby-order-hierarchy',
  svg: true,

}

const customerUsage = {
  title: "Charge customers for their usage with allowances.",
  list: [
    {
      title: 'Create as many allowances as you want',
      description: <>Bill per minute, per seat or per yoga session, tracking usage as the billing cycle progresses and charging for it at the end.</>,
    },
    {
      title: 'Force allowances with whichever plans you\'d like ',
      description: <>Include overage-only allowances, so that you can charge customers on an arrears basis for the services they use - using any of our pricing models.</>,
    },
    {
      title: 'Cap overage on allowances',
      description: <>Tell us to apply an overage cap, and we'll make sure customers aren't billed for any excess usage, whilst helping you to keep track of what they've consumed.</>,
    }
  ],
  image: require('../../../images/checkout-payments.svg'),
  imageName: 'Checkout Payment',
  svg: true,
  align: 'right'
}



const futureTags = {
  title: "Keep count in real time and manage access with our API and Feature Tags",
  list: [
    {
      title: 'Send real-time usage updates to our API',
      description: <> Unlike other billing systems, there's no need to wait until the end of the month and keep count of usage yourself, just tell us what your customers have used.</>,
    },
    {
      title: 'Get allowance updates programmatically',
      description: <>You can check customers counters at any time to make sure they've got enough allowance left to do something, or to provide usage tracking on your website.</>,
    },
    {
      title: 'Update access control and permissions with Feature Tags',
      description: <>You can associate Feature Tags with add-ons and allowances to determine what permissions customers should have within your apps and integrations.</>,
    }
  ],
  image: require('../../../images/api-relay-race.svg'),
  imageName: 'Big Savings',
  svg: true,
  align: 'left'
}


const AddonsAllowances = () => {
  return (
    <Layout className="developers addons-allowances">
      <SEO 
        title="Add-Ons and Allowances | Billsby | Powerful, customizable subscription billing software" 
        description="Build advanced plans with add-ons and allowances for your subscription billing or recurring payments business with a simple and easy-to-use plan creator." 
        url="https://www.billsby.com/product/creating/addons-allowances"
      />

      <TwoColumn content={increaseMMR}/>
      <CustomerJourney content={bundleAddons} />
      <CustomerJourney content={customerUsage} />
      <CustomerJourney content={futureTags} />
    </Layout>
  )
}

export default AddonsAllowances